// Import necessary libraries and components
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import {
  selectConfigPairs,
  selectPairData,
  selectStrategiesConfig,
  selectPairOrders,
} from 'redux/selectors';
import { dispatch as CustomDispatch } from 'redux/actions/index';

// Material-UI imports
import {
  makeStyles,
  Grid,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { grey, white } from '@material-ui/core/colors';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

// Component imports
import SettingPanel from 'components/Setting/OverviewCardSettingPanel';
import GetUserTemplates from 'components/Gunbot/GetUserTemplates';
import parseInput from 'components/Gunbot/ParseInput';
import $scope from 'consts/scope';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import tooltipStyle from 'assets/jss/material-dashboard-react/tooltipStyle';
import { deepCompare, truncate, countDecimals, getValueColor } from 'assets/js/common';
import scientificToDecimal from 'scientific-to-decimal';

// Import debounce utility from lodash
import debounce from 'lodash/debounce';

// Helper functions
function isNil(value) {
  return value == null;
}

function invert(obj) {
  const result = {};
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const val = obj[key];
      result[val] = key;
    }
  }
  return result;
}

function isFiniteNumber(value) {
  return Number.isFinite(value);
}

function last(array) {
  return array[array.length - 1];
}

// Function to strip exchange slot number from exchange name
function stripAppendix(str) {
  if (!str) {
    return '';
  }
  return str.replace(/#\d+$/, '');
}
function minimalMarkdownToHTML(text = "") {
  let html = text
    // Add inline style for color in headings
    .replace(/^### (.*$)/gim, "<h3 style='color:#66AAFF; margin-top:1em;'>$1</h3>")
    .replace(/^## (.*$)/gim, "<h2 style='color:#66AAFF; margin-top:1em;'>$1</h2>")
    .replace(/^# (.*$)/gim, "<h1 style='color:#66AAFF; margin-top:1em;'>$1</h1>")

    // Bold
    .replace(/\*\*(.*?)\*\*/gim, "<b>$1</b>")

    // Italic
    .replace(/\*(.*?)\*/gim, "<i>$1</i>")

    // List items
    .replace(/^- (.*$)/gim, "<li>$1</li>")

    // Line breaks
    .replace(/\n/gim, "<br/>");

  return html.trim();
}

// Styles for the component
const styles = (theme) => ({
  ...dashboardStyle,
  settingPanel: {
    height: '400px',
    overflow: 'auto',
  },
  overviewHeader: {
    padding: '14px 0 0 0 !important',
  },
  cardHeaderContent: {
    [theme.breakpoints.up('lg')]: {
      flexWrap: 'nowrap',
    },
  },
  chartWrapper: {
    [theme.breakpoints.up('lg')]: {
      flex: 'auto',
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  descriptionWrapper: {
    minWidth: 200,
    fontSize: 22,
    overflowX: 'hidden',
    overflowY: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 3,
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  cardHeaderContentAlone: {
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
    },
  },
  chartWrapperAlone: {
    [theme.breakpoints.up('sm')]: {
      flex: 'auto',
    },
  },
  descriptionWrapperAlone: {
    minWidth: 200,
    fontSize: 22,
    overflowX: 'hidden',
    overflowY: 'hidden',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 3,
    },
  },
  root: {
    flexGrow: 1,
  },
});

// Combine default strategies from scope
const defaultStratAll = { ...$scope.defaultStrat, ...$scope.defaultStratBR, ...$scope.defaultStratMM };

// Create styles using makeStyles hook
const useStyles = makeStyles(styles);

// Styled tooltip component
const StyledTooltip = withStyles(() => ({
  tooltip: tooltipStyle.tooltip,
}))(Tooltip);

// OverViewCard Component
function OverViewCard(props) {
  // Destructure props
  const {
    pair,
    exchange = '',
    alone = false,
    sideBar = false,
    showSettings = false,
    showStats = false,
    isMobile = false,
  } = props;

  // Initialize hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Create a unique key for the pair
  const key = `${exchange}/${pair}`;

  // Select data from Redux store
  const pairData = useSelector(selectPairData(key) || {});
  const pairs = useSelector(selectConfigPairs);
  const strategies = useSelector(selectStrategiesConfig);
  const exchangeDetails = useSelector((state) => state.settings.config.exchanges);
  const orders = useSelector(selectPairOrders(key));

  // Local state variables
  const [extrasExpanded, setExtrasExpanded] = useState(true);
  const [userTemplates, setUserTemplates] = useState({});
  const [eest, setEest] = useState('Estimated holding');
  const [bep, setBep] = useState('Break even');
  const [settingData, setSettingData] = useState([]);
  const [sortedStrategies, setSortedStrategies] = useState([]);
  const [newOverride, setNewOverride] = useState({ label: '', value: '' });

  // Data state to hold various calculated values
  const [data, setData] = useState({
    abp: 'loading',
    roe: 'loading',
    ask: 'loading',
    bid: 'loading',
    profitMM: 'loading',
    bag: 'loading',
    bagCurrency: '',
    state: '',
    side: 'loading',
    leverage: 'loading',
    maintenanceMargin: 'loading',
    unrealizedROEpcnt: 'loading',
    availableMargin: 'loading',
    liquidationPrice: 'loading',
    marginRatio: 'loading',
    marginUsage: 'loading',
    freeCollateral: 'loading',
  });

  // Strip exchange slot number if present
  const strippedExchange = stripAppendix(exchange || '');

  // Determine the market type for various exchanges
  const exchangeMarket = exchangeDetails[exchange]?.market || 'spot';

  // Function to handle the expansion of extra details
  const handleExpand = () => {
    setExtrasExpanded(!extrasExpanded);
  };

  // Fetch user templates with debouncing
  useEffect(() => {
    const debouncedFetchTemplates = debounce(async () => {
      if (Object.keys(userTemplates).length < 1) {
        let result = await GetUserTemplates();
        if (Object.keys(result).length < 1) {
          result = { placeholder_: true };
        } else {
          let finalResult = {};
          Object.keys(result).forEach((element) => {
            finalResult[element.toLowerCase()] = result[element];
          });
          result = finalResult;
        }
        setUserTemplates(result);
      }
    }, 10000); // 10 seconds debounce

    debouncedFetchTemplates();

    return () => {
      debouncedFetchTemplates.cancel();
    };
  }, [userTemplates]);

  // Update data based on pairData
  useEffect(() => {
    if (isNil(pairData)) {
      return;
    }

    let abp = 'loading';
    let roe = 'loading';
    let aboveMvts = false;

    // Check if certain data is available
    if (
      !isNil(pairData.Bid) &&
      !isNil(pairData.ABP) &&
      !isNil(pairData.quoteBalance) &&
      !isNil(pairData.whatstrat?.MIN_VOLUME_TO_SELL) &&
      !isNil(pairData.reversal)
    ) {
      aboveMvts =
        (pairData.quoteBalance - parseFloat(pairData.whatstrat.KEEP_QUOTE)) * pairData.Bid >
          parseFloat(pairData.whatstrat.MIN_VOLUME_TO_SELL) || pairData.reversal;
    }

    // Determine ABP and ROE based on market type
    if (!exchangeMarket.includes('spot')) {
      roe = !isNil(pairData.precalculatedFuturesRoe) ? pairData.precalculatedFuturesRoe : 'loading';
      abp = !isNil(pairData.precalculatedAvgEntryPrice) ? pairData.precalculatedAvgEntryPrice : 'loading';

      if (exchangeMarket === 'delivery') {
        setEest('Pos. size (coin|usd)');
      } else {
        setEest('Position size');
      }
    } else {
      roe = !isNil(pairData.precalculatedSpotRoe) ? pairData.precalculatedSpotRoe : 'loading';
      abp = !isNil(pairData.precalculatedSpotAbp) ? pairData.precalculatedSpotAbp : 'loading';
      setEest('Bag value');
    }

    // Initialize other variables
    let side = 'n/a';
    let leverage = 'n/a';
    let maintenanceMargin = 'n/a';
    let unrealizedROEpcnt = 'n/a';
    let availableMargin = 'n/a';
    let liquidationPrice = 'n/a';
    let marginRatio = 'n/a';
    let marginUsage = 'n/a';
    let freeCollateral = 'n/a';

    // Set precision for unrealized ROE percentage
    let unrealizedPrecision = 2;

    if (exchangeMarket === 'delivery') {
      unrealizedPrecision = 4;
    }

    // Helper functions for safe number formatting
    const safeToFixed = (value, precision = 2) => {
      return isNil(value) ? 'n/a' : parseFloat(value).toFixed(precision);
    };

    const safeToPrecision = (value, precision = 2) => {
      return isNil(value) ? 'loading' : parseFloat(value).toPrecision(precision);
    };

    const safeParseFloat = (value) => {
      return isNil(value) ? 'loading' : scientificToDecimal(parseFloat(value));
    };

    // Check if the exchange is a futures market
    const isFuturesMarket = (exchangeName, market) => {
      const futuresExchanges = [
        'okex5',
        'okgunbot',
        'bitget',
        'binanceFutures',
        'dydx',
        'dydx4',
        'futures_gunthy',
        'krakenFutures',
        'bitmex',
        'bitmex_testnet',
        'kumex',
        'bybit',
        'ftx',
      ];
      return (
        (futuresExchanges.includes(exchangeName) && market !== 'spot') ||
        (exchangeName === 'ftx' && market === 'futures')
      );
    };

    // Update data if in a futures market
    if (isFuturesMarket(strippedExchange, exchangeMarket)) {
      // Determine position side
      side =
        isNil(pairData.currentQty) || pairData.currentQty === 0
          ? 'No position'
          : pairData.currentQty < 0
          ? 'Short'
          : 'Long';

      leverage = safeToFixed(Math.abs(pairData.leverage), 0) + 'x';
      maintenanceMargin = safeToFixed(pairData.maintenanceMargin);
      unrealizedROEpcnt = safeToFixed(pairData.unrealizedROEpcnt, unrealizedPrecision);
      availableMargin =
        safeToFixed(pairData.availableForOrders) ||
        safeToFixed(pairData.availableMargin) ||
        safeToFixed(pairData.availableFunds);
      liquidationPrice = safeToFixed(pairData.liquidationPrice, 4);

      // Special case for delivery market
      if (exchangeMarket === 'delivery') {
        unrealizedROEpcnt += ` | ${safeToFixed(pairData.unrealizedROEpcnt * pairData.Bid, 2)}`;
      }

      // Adjust available margin for certain exchanges
      if (!isNil(pairData.whatstrat) && !isNil(pairData.whatstrat.PAIR)) {
        if (
          ['binanceFutures', 'dydx', 'dydx4', 'futures_gunthy'].includes(exchange) &&
          pairData.whatstrat.PAIR.split('-')[0] !== 'USDT'
        ) {
          availableMargin = safeToFixed(pairData.availableForOrders, 4);
        }

        if (exchange === 'ftx') {
          availableMargin = safeToFixed(pairData.baseBalance, 4);
        }
      }

      // Calculate margin ratio
      marginRatio = `${safeToFixed(
        (parseFloat(pairData.maintenanceMargin) / parseFloat(pairData.availableMargin)) * 100,
        2
      )}%`;
    }

    // Parse ask and bid prices
    const ask = safeParseFloat(pairData.Ask);
    const bid = safeParseFloat(pairData.Bid);

    // Calculate profit
    let profitMM = truncate(last(pairData.globaltotalholdingUSDTV), countDecimals(ask));
    profitMM = profitMM || 0.00000001;

    // Initialize bag and currency
    let bag = 'loading';
    let bagCurrency = '';

    // Determine bag value based on market type
    if (!exchangeMarket.includes('spot') && !isNil(pairData.currentQty)) {
      bag = pairData.currentQty;
      if (exchangeMarket === 'delivery') {
        bag += ` | ${safeToPrecision(bag * pairData.Bid, 2)}`;
      }
    } else if (!isNil(pairData.reversal)) {
      if (pairData.reversal === true) {
        bag = safeToFixed(pairData.ourBaginBase);
      } else {
        bag = aboveMvts
          ? safeToFixed(
              pairData.quoteBaseValue ||
                parseFloat(pairData.quoteBalance) * parseFloat(pairData.Bid),
              pair.split('-')[0].includes('USD') ? 2 : 6
            )
          : pairData.quoteBaseValue > 0
          ? safeToFixed(pairData.quoteBaseValue, pair.split('-')[0].includes('USD') ? 2 : 6)
          : 'n/a';
      }

      bagCurrency = pairData.whatstrat && pairData.whatstrat.PAIR
        ? pairData.whatstrat.PAIR.split('-')[0]
        : 'n/a';
    }

    // Get state from pairData
    const state = pairData.state;

    // Calculate margin usage and free collateral
    marginUsage = `${safeToFixed(
      (1 - parseFloat(pairData.maintenanceMargin) / parseFloat(pairData.walletBalance)) * 100,
      2
    )}%`;
    freeCollateral = parseFloat(pairData.maintenanceMargin);

    // Handle privacy mode
    if (localStorage.getItem('privacyMode') === 'true') {
      profitMM = '●●●●●';
      bag = '●●●●●';
      abp = '●●●●●';
      maintenanceMargin = '●●●●●';
      roe = '●●●●●';
      unrealizedROEpcnt = '●●●●●';
      availableMargin = '●●●●●';
      liquidationPrice = '●●●●●';
      marginRatio = '●●●●●';
      marginUsage = '●●●●●';
      freeCollateral = '●●●●●';
    }

    // Update data state
    setData({
      abp,
      ask,
      roe,
      bid,
      profitMM,
      bag,
      bagCurrency,
      state,
      side,
      leverage,
      maintenanceMargin,
      unrealizedROEpcnt,
      availableMargin,
      liquidationPrice,
      marginRatio,
      marginUsage,
      freeCollateral,
    });
  }, [pairData, exchange, exchangeMarket, strippedExchange]);

  // Update break-even price label based on conditions
  useEffect(() => {
    let newBep = 'Break even';

    if (!isNil(pairData)) {
      if (!isNil(pairData.whatstrat)) {
        if (
          pairData.whatstrat.unit_cost === true ||
          (pairData.whatstrat.DYNAMIC_EXIT_LOGIC === true && pairData.strategySetABP !== pairData.ABP)
        ) {
          newBep = 'Unit cost';
        }
      }
    }

    if (!exchangeMarket.includes('spot')) {
      newBep = 'Entry price';
    }

    setBep(newBep);
  }, [pairData, exchangeMarket]);

  // Update sorted strategies
  useEffect(() => {
    const newSortedStrategies = Object.keys(strategies).sort();
    setSortedStrategies((prevStrategies) => {
      return deepCompare(prevStrategies, newSortedStrategies) ? prevStrategies : newSortedStrategies;
    });
  }, [strategies]);

  // Update setting data
  useEffect(() => {
    if (!exchange || !pair) return;

    // Get pair configuration
    const exchanges = pairs?.[exchange] || {};
    const pairConfig = exchanges[pair] || {};
    const overrideData = pairConfig.override || {};

    // Check if it's a user strategy
    const isUserStrat = !isNil(exchanges?.[pair])
      ? Object.keys(userTemplates).includes(exchanges[pair].strategy)
      : false;

    // Function to add an override
    const addOverride = () => {
      const { value, label } = newOverride;
      dispatch(CustomDispatch('putConfig', value, label, 'override', pair, exchange, 'pairs'));
      setNewOverride({ value: '', label: '' });
    };

    // Function to remove an override
    const removeOverride = (key) => {
      dispatch(CustomDispatch('putConfig', undefined, key, 'override', pair, exchange, 'pairs'));
    };

    // Prepare user settings lists
    let userSettingsList = {};
    let userSettingsListTooltips = {};
    let userSettingsDefaultValues = {};
    let userSettingsListInverted = {};

    const allTemplates = { ...userTemplates, ...$scope.easyStratEditor };
    const stratRef = exchanges?.[pair]?.strategy || 'stepgrid';

    if (allTemplates?.[stratRef]) {
      Object.keys(allTemplates[stratRef]).forEach((section) => {
        if (section === 'info') {
          return;
        }

        const parameters = allTemplates[stratRef][section]?.parameters || {};
        Object.keys(parameters).forEach((setting) => {
          userSettingsList[setting] = parameters[setting].label;
          userSettingsListTooltips[setting] = parameters[setting]?.tooltip || '';
          userSettingsDefaultValues[setting] = parameters[setting].defaultValue;
        });
      });
    }

    userSettingsListInverted = invert(userSettingsList);

    // Create a list of readable override options
    let readableList = $scope.overrides.map((item) => $scope.title[item]);
    readableList = [...readableList, ...Object.values(userSettingsList)];

    // Prepare override data for the SettingPanel
    const renderedOverrideData = [
      {
        type: 'form',
        label: 'Add',
        name: 'overrideSetting',
        children: [
          {
            name: 'label',
            label: 'Parameter',
            value:
              userSettingsList[newOverride.label] ||
              $scope.title[newOverride.label] ||
              newOverride.label,
            tooltip: 'Select a strategy parameter to override for this pair',
            options: readableList.sort(),
            labelWidth: 'auto',
            valueWidth: 'auto',
            placeholder: 'BTC-ETC',
            onChanged: (newValue) => {
              const labelKey = isUserStrat
                ? userSettingsListInverted[newValue]
                : invert($scope.title)[newValue];
              setNewOverride({
                label: labelKey,
                value: isUserStrat
                  ? userSettingsDefaultValues[labelKey]
                  : !isNil(defaultStratAll[labelKey])
                  ? parseInput(defaultStratAll[labelKey])
                  : '0',
              });
            },
            onInputChanged: (newValue) => {
              const labelKey = isUserStrat
                ? userSettingsListInverted[newValue]
                : invert($scope.title)[newValue];
              setNewOverride({
                label: labelKey,
                value: isUserStrat
                  ? userSettingsDefaultValues[labelKey]
                  : !isNil(defaultStratAll[labelKey])
                  ? parseInput(defaultStratAll[labelKey])
                  : '0',
              });
            },
          },
          {
            name: 'value',
            label: 'Value',
            value: newOverride.value,
            tooltip:
              userSettingsListTooltips[newOverride.label] ||
              $scope.tooltips[newOverride.label] ||
              '',
            placeholder: 'Override Value',
            onChanged: (newValue) => {
              setNewOverride({
                ...newOverride,
                value: parseInput(newValue),
              });
            },
          },
        ],
        onClick: addOverride,
      },
      // Filter out removed overrides before mapping
      ...Object.keys(overrideData)
        .filter((label) => overrideData[label] !== undefined && overrideData[label] !== null)
        .map((label) => {
          const value = overrideData[label];
          return {
            name: label,
            label:
              userSettingsListInverted[label] ||
              userSettingsList[label] ||
              $scope.title[label] ||
              label,
            tooltip: userSettingsListTooltips[label] || $scope.tooltips[label] || '',
            value: value === false ? false : value ? parseInput(value) : null,
            labelWidth: '195px',
            valueWidth: 'auto',
            removable: true,
            onRemove: () => {
              removeOverride(label);
            },
            onChanged: (newValue) => {
              dispatch(
                CustomDispatch('putConfig', parseInput(newValue), label, 'override', pair, exchange, 'pairs')
              );
            },
          };
        }),
    ];

    // Update setting data if pair is configured
    if (Object.keys(exchanges).length > 0 && Object.keys(exchanges).includes(pair)) {
      setSettingData([
        {
          name: 'enabled',
          value: exchanges?.[pair]?.enabled || false,
          label: 'Enabled',
          width: '100%',
          onChanged: (newValue) => {
            dispatch(CustomDispatch('putConfig', newValue, 'enabled', pair, exchange, 'pairs'));
          },
        },
        {
          type: 'component',
          component: (
            <div style={{ marginTop: 20 }}>
              <SettingPanel
                className={'override-setting-panel'}
                data={renderedOverrideData}
                isSub={true}
                title={'Override Settings'}
                description={'Add or modify override settings for the selected pair.'}
              />
            </div>
          ),
        },
      ]);
    }
  }, [pair, exchange, pairs, sortedStrategies, newOverride, userTemplates, dispatch]);

  // Destructure data state for ease of use
  const {
    abp,
    ask,
    roe,
    bid,
    bag,
    bagCurrency,
    side,
    leverage,
    unrealizedROEpcnt,
    availableMargin,
    liquidationPrice,
    marginRatio,
    marginUsage,
    freeCollateral,
  } = data;

  // Determine ROE label
  let showRoe = 'ROE';

  // Return null if pair is not specified
  if (!pair) {
    return null;
  }

  // Render sideBar with statistics
  if (sideBar && showStats) {
    return (
      <div className={'overview-pair-card'} style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '80vh' }}>
        {/* Render pair information */}
        <div className={classes.root} style={{ overflow: 'hidden' }}>
          {/* Pair name and prices */}
          <Grid container spacing={2}>
            <Grid item xs style={{ textAlign: 'center' }}>
              <p className={classes.cardCategory} style={{ fontSize: '22px', color: grey[200] }}>
                {pair.replace('-', ' - ')}
              </p>
              <h6 className={classes.cardTitleSidebar} style={{ fontSize: 'medium', fontWeight: '600' }}>
                <span style={{ color: '#0ECB81' }}> {bid}</span> /{' '}
                <span style={{ color: '#F6465D' }}>{ask} </span>
              </h6>
            </Grid>
          </Grid>

          {/* ROE, Break-even price, Bag value */}
          <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
            {/* ROE */}
            <Grid item xs>
              <StyledTooltip
                placement="top"
                title={`Return on Equity (ROE) measures the profitability of your investment relative to the amount of money you've put into it. It shows your net profit after considering any commissions or fees you've paid.`}
              >
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>{showRoe}</small>
                  <br />
                  <span
                    style={{
                      color: getValueColor(roe),
                      fontSize: 'larger',
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                  >
                    {' '}
                    {roe}
                  </span>
                </h5>
              </StyledTooltip>
            </Grid>

            {/* Break-even price */}
            <Grid item xs>
              <StyledTooltip
                placement="top"
                title={`The cost of a single unit of the asset.`}
              >
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>{bep}</small>
                  <br />
                  <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{abp}</span>
                </h5>
              </StyledTooltip>
            </Grid>

            {/* Bag value */}
            <Grid item xs>
              <StyledTooltip
                placement="top"
                title={`This represents the total amount of trading capital invested in this asset at this time.`}
              >
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>
                    {eest} {eest === 'Bag value' ? bagCurrency : null}
                  </small>
                  <br />
                  <span style={{ color: grey[200], fontSize: 'larger', fontWeight: '600', textAlign: 'center' }}>
                    {' '}
                    {bag}{' '}
                  </span>
                </h5>
              </StyledTooltip>
            </Grid>
          </Grid>

          {/* Additional stats for futures markets */}
          {strippedExchange === 'bitmex' ||
          strippedExchange === 'bitmex_testnet' ||
          strippedExchange === 'krakenFutures' ? (
            <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
              {/* Side */}
              <Grid item xs>
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Side</small>
                  <br />
                  <span style={{ color: grey[200], fontSize: 'larger', fontWeight: '600', textAlign: 'center' }}>
                    {side}
                  </span>
                </h5>
              </Grid>

              {/* Leverage */}
              <Grid item xs>
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Leverage</small>
                  <br />
                  <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{leverage}</span>
                </h5>
              </Grid>

              {/* Liquidation price */}
              <Grid item xs>
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Liquidation price</small>
                  <br />
                  <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>
                    {liquidationPrice}
                  </span>
                </h5>
              </Grid>
            </Grid>
          ) : null}

          {/* Additional stats for other futures markets */}
          {strippedExchange === 'binanceFutures' ||
          strippedExchange === 'futures_gunthy' ||
          (strippedExchange === 'bybit' && !exchangeMarket.includes('spot')) ||
          (strippedExchange === 'ftx' && exchangeMarket === 'futures') ||
          (strippedExchange === 'okgunbot' && exchangeMarket !== 'spot') ||
          (strippedExchange === 'okex5' && exchangeMarket !== 'spot') ? (
            <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
              {/* Side */}
              <Grid item xs>
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Side</small>
                  <br />
                  <span style={{ color: grey[200], fontSize: 'larger', fontWeight: '600', textAlign: 'center' }}>
                    {side}
                  </span>
                </h5>
              </Grid>

              {/* Leverage */}
              <Grid item xs>
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Leverage</small>
                  <br />
                  <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{leverage}</span>
                </h5>
              </Grid>

              {/* Margin ratio */}
              <Grid item xs>
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Margin ratio</small>
                  <br />
                  <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{marginRatio}</span>
                </h5>
              </Grid>
            </Grid>
          ) : null}

          {/* Unrealized PNL and other stats */}
          {strippedExchange === 'binanceFutures' ||
          strippedExchange === 'futures_gunthy' ||
          (strippedExchange === 'bybit' && !exchangeMarket.includes('spot')) ||
          (strippedExchange === 'ftx' && exchangeMarket === 'futures') ||
          (strippedExchange === 'okgunbot' && exchangeMarket !== 'spot') ||
          (strippedExchange === 'bitget' && exchangeMarket !== 'spot') ||
          (strippedExchange === 'okex5' && exchangeMarket !== 'spot') ? (
            <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
              {/* Unrealized PNL */}
              <Grid item xs>
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Unrealized PNL</small>
                  <br />
                  <span
                    style={{
                      color: getValueColor(roe),
                      fontSize: 'larger',
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                  >
                    {unrealizedROEpcnt}
                  </span>
                </h5>
              </Grid>

              {/* Liquidation price */}
              <Grid item xs>
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Liquidation price</small>
                  <br />
                  <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>
                    {liquidationPrice}
                  </span>
                </h5>
              </Grid>

              {/* Available margin */}
              <Grid item xs>
                <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                  <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Avail. for orders</small>
                  <br />
                  <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>
                    {availableMargin}
                  </span>
                </h5>
              </Grid>
            </Grid>
          ) : null}
        </div>

        {/* Render extra details if available */}
        {!isNil(pairData) && !isNil(pairData.sidebarExtras) && pairData.sidebarExtras.length > 0 && extrasExpanded && (
         <Grid container spacing={1} style={{ marginBottom: "8px", marginTop: "8px" }}>
         {pairData.sidebarExtras.map((item, key) => {
           const isMarketAnalysis = item.label?.startsWith("Market Analysis - ");
           const itemValue = item.value || "";
       
           return (
             <Grid
               item
               xs={isMarketAnalysis ? 12 : 4}
               lg={isMarketAnalysis ? 12 : 4}
               xl={isMarketAnalysis ? 12 : 4}
               key={key}
             >
               <StyledTooltip placement="top" title={item?.tooltip || ""}>
                 {/* Use the same heading and style you had before */}
                 <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                   {item.label && (
                     <>
                       <small
                         style={{
                           fontSize: 'small',
                           lineHeight: 1.5,
                           marginRight: '0rem',
                           fontWeight: 500,
                         }}
                       >
                         {item.label}
                       </small>
                       <br />
                     </>
                   )}
       
                   {/* If it's Market Analysis, apply the minimal Markdown transform with a smaller font */}
                   {isMarketAnalysis ? (
                     <div
                       style={{
                         textAlign: 'left',
                         display: 'inline-block',
                         marginTop: '8px',
                         fontSize: 'small',
                         lineHeight: 1.5,
                       }}
                       dangerouslySetInnerHTML={{ __html: minimalMarkdownToHTML(itemValue) }}
                     />
                   ) : (
                     <span
                       style={{
                         color: isNil(item.valueColor) ? grey[200] : item.valueColor,
                         fontSize: 'larger',
                         fontWeight: 600,
                       }}
                     >
                       {itemValue}
                     </span>
                   )}
                 </h5>
               </StyledTooltip>
             </Grid>
           );
         })}
       </Grid>
       
     )}

        {/* Expand/collapse button for extra details */}
        {!isNil(pairData) && !isNil(pairData.sidebarExtras) && pairData.sidebarExtras.length > 0 && !isMobile && (
          <IconButton component="span" onClick={handleExpand} color="default" size="small">
            {extrasExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </div>
    );
  }

  // Render sideBar with settings
  if (sideBar && showSettings) {
    return (
      <div className={'overview-pair-card'} >
        <SettingPanel data={settingData} />
      </div>
    );
  }

  // Return null if no conditions are met
  return null;
}

// Define prop types for the component
OverViewCard.propTypes = {
  pair: PropTypes.string,
  exchange: PropTypes.string,
  alone: PropTypes.bool,
  sideBar: PropTypes.bool,
  showSettings: PropTypes.bool,
  showStats: PropTypes.bool,
  isMobile: PropTypes.bool,
};

// Export the component using React.memo for performance optimization
export default React.memo(OverViewCard);
